<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <div class="d_f">
      <div class="flex_1">
        <!--订单明细-->
        <div class="uc_con">
          <div class="uc_title d_f ali_c flex_1">
            <div class="uc_green"></div>
            <div>订单明细</div>
            <div class="or_choose d_f ali_c">
              <div class="all_or" @click="radioIndex = 0">
                <span class="ch" :class="radioIndex == 0 ? 'ac' : ''"><img src="../assets/images/or_check.png" alt=""></span>
                <span>显示所有</span>
              </div>
              <div @click="radioIndex = 1">
                <span class="ch" :class="radioIndex == 1 ? 'ac' : ''"><img src="../assets/images/or_check.png" alt=""></span>
                <span>只显示可退款</span>
              </div>
            </div>
          </div>
          <div class="d_f ali_b j_s">
            <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
              <el-form-item label="游客姓名">
                <el-input v-model="searchInfo.searchKeyName" placeholder="输入游客姓名进行搜索"></el-input>
              </el-form-item>
              <el-form-item label="证件号码">
                <el-input v-model="searchInfo.searchKeyNum" placeholder="输入证件号码进行搜索"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="resetSearch">重置</el-button>
                <el-button type="primary" @click="search">查询</el-button>
              </el-form-item>
            </el-form>
            <span class="or_num">订单号：{{$route.query.orderNumber}}</span>
          </div>
          <el-table
            ref="multipleTable"
            :data="tableData"
            stripe
            :header-cell-style="{'text-align':'center','background-color': '#F4F4F4','font-size': '14px','color': '#333','font-weight': 'bold'}"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >

            <!--:span-method="objectSpanMethod"-->
            <el-table-column type="selection" :selectable='canCheckRow' align="center" width="55"></el-table-column>
            <el-table-column type="expand" align="center" width="55">
              <template #default="props">
                <el-table
                  ref="multipleTable"
                  :data="props.row.users"
                  tooltip-effect="dark"
                  style="width: 100%"
                >
                  <el-table-column prop="commodityName" label="商品名称" align="center">
                    <template #default="scope">{{ scope.row.commodityName }}</template>
                  </el-table-column>
                  <el-table-column prop="visitorName" label="游客姓名" align="center"> </el-table-column>
                  <el-table-column label="证件类型" align="center" show-overflow-tooltip>
                    <template #default="scope"><span>{{getZJlX(scope.row.credentialsType)}}</span></template>

                  </el-table-column>
                  <el-table-column prop="certificateNumber" label="证件号码" align="center" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="ticketStatus" label="状态" align="center" show-overflow-tooltip>
                    <template #default="scope"><span>{{staArr[scope.row.ticketStatus].name}}</span></template>

                  </el-table-column>
                  <el-table-column prop="sellPrice" label="渠道价" align="center" show-overflow-tooltip>
                    <template #default="scope"><span>{{scope.row.sellPrice ? scope.row.sellPrice/100 : 0}}</span></template>

                  </el-table-column>
                  <el-table-column prop="beginDate" label="开始时间" align="center" show-overflow-tooltip>
                    <template #default="scope"><span>{{scope.row.beginDate ? scope.row.beginDate.split(' ')[0]: '--'}}</span></template>

                  </el-table-column>
                  <el-table-column prop="endDate" label="结束时间" align="center" show-overflow-tooltip>
                    <template #default="scope"><span>{{scope.row.endDate ? scope.row.endDate.split(' ')[0]: '--'}}</span></template>

                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="groupName" label="商品名称" align="center"> </el-table-column>
            <el-table-column prop="reArr" label="门票状态" align="center">
              <template #default="scope">{{ scope.row.orderDetailStatus ? staArr[scope.row.orderDetailStatus].name : '--' }}</template>
            </el-table-column>
            <el-table-column prop="reArr" label="退款状态" align="center">
              <template #default="scope">{{ scope.row.refundStatus ? reArr[scope.row.refundStatus] : '--' }}</template>
            </el-table-column>
            <el-table-column prop="ticketNumber" label="票号" align="center"> </el-table-column>


          </el-table>
          <div class="d_f or_bottom ali_c">
            <div class="d_f ali_b">
              <span>已选票数</span>
              <span class="bold"> {{chooseArr.length}}张</span>
              <span>金额总计</span>
              <span class="bold red_text">￥{{choosePri}}</span>
            </div>
            <div class="flex_1 t_r">
              <div class="ch_btn t_r">
                <div @click="showPop">发起退款申请</div>
                <div class="ac" @click="$router.go(-1)">取消</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="td_r"></div>
    </div>
    <div class="td_rp p_f">
      <div class="tdr_title p_r t_a">
        <span>订单退款</span>
        <div class="p_a"></div>
      </div>
      <div class="ch_btn">
        <div @click="$router.go(-1)">返回上一级</div>
      </div>
    </div>

    <!--payPop-->
    <el-dialog
      v-model="dialogVisible"
      width="40%"
      :show-close="false"
    >
      <template #title>
        <div class="uc_title d_f ali_c flex_1">
          <div class="uc_green"></div>
          <div>确定退款</div>
          <div class="flex_1 t_r" @click="dialogVisible = false">
            <img class="close" src="../assets/images/bk_close.png" alt="">
          </div>
        </div>
      </template>
      <div class="td_pop_con">
        <div class="pop_or">退款金额将原路退回原支付账户</div>
        <div>金额总计 <span class="red_text">{{choosePri}}</span></div>
        <!--<div>退款手续费<span>{{}}</span></div>-->
        <div>发起退款申请后，工作人员会在3个工作日内将退款结果发至您的预留手机，请注意查收。</div>
      </div>

      <template #footer>

        <div class="t_a ch_btn">
          <div class="cp_btn" @click="save">确定</div>
          <div class="cp_btn ac" @click="dialogVisible = false">取消</div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "orderRefund",
    watch:{
      radioIndex(n,o){
        if(n == 0){
          this.tableData = JSON.parse(JSON.stringify(this.details))
        }else if(n == 1){
          this.tableData = JSON.parse(JSON.stringify(this.refundArr))

        }
      },
    },
    data() {
      return {
        canRefund:false,
        chooseArr:[],
        chooseArrSend:[],
        choosePri:0,
        cardArr:[],
        staArr:[
          {value:0,name:'待支付'},
          {value:1,name:'待出票'},
          {value:2,name:'已取消'},
          {value:3,name:'已关闭'},
          {value:4,name:'出票失败'},
          {value:5,name:'退款成功'},
          {value:6,name:'未使用'},
          {value:7,name:'已使用'},
        ],
        // PROCESSING(1L, "退款中"),
        // REFUND_SUCCESS(2L, "退款成功"),
        // REFUND_FAIL(3L, "退款成功");
        reArr:[
          '','退款中','退款成功','退款失败'
        ],
        info:{details:[],orderDetails:[],order:{}},
        details:[],
        refundArr:[],
        tableData:[],
        radioIndex:0,
        dialogVisible:false,
        searchInfo:{
          searchKeyName:'',
          searchKeyNum:'',
        },
      }
    },
    created() {
      this.queryWinCredentials();
      this.getDetail();
    },
    onBeforeRouteLeave(){
      window.clearInterval(this.interval);
      this.interval = null;
    },
    methods: {

      /*//合并行
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        // columnIndex === xx 找到第xx列，实现合并随机出现的行数
        if (columnIndex === 1 || columnIndex === 2) {
          const _row = this.spanArr[rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col
          };
        }
      },
      // 因为要合并的行数是不固定的，此函数是实现合并随意行数的功能
      getSpanArr(data) {
        console.log("zmmm--",data)//从后台获取的数据
        this.spanArr = [];
        this.pos = 0;
        for (var i = 0; i < data.length; i++) {
          if (i === 0) {
            // 如果是第一条记录（即索引是0的时候），向数组中加入１
            this.spanArr.push(1);
            this.pos = 0;
          } else {
            if (data[i].orderGroupDetailId === data[i - 1].orderGroupDetailId) {
              // 如果itemCode相等就累加，并且push 0
              this.spanArr[this.pos] += 1;
              this.spanArr.push(0);
            } else {
              // 不相等push 1
              this.spanArr.push(1);
              this.pos = i;
            }
          }
        }
      },*/
      handleSelectionChange(val){
        this.chooseArrSend = [];
        this.chooseArr = JSON.parse(JSON.stringify(val));
        let num = 0;
        this.chooseArr.map(item => {
          if(item.sellPrice) num += item.sellPrice;
          item.users.map(itm => {
            this.chooseArrSend.push(itm)
          })
        });
        this.choosePri = num ? num/100 : 0

      },
      resetSearch(){
        this.searchInfo = {
          searchKeyName:'',
          searchKeyNum:'',
        };
        this.search();
      },
      search(){
        let arr = [];
        let cArr = this.radioIndex == 0 ? JSON.parse(JSON.stringify(this.details)) : JSON.parse(JSON.stringify(this.refundArr));
        cArr.map(item => {
          if(item.visitorName.indexOf(this.searchInfo.searchKeyName) > -1 && item.certificateNumber.indexOf(this.searchInfo.searchKeyNum) > -1){
            arr.push(item)
          }
        });
        this.tableData = JSON.parse(JSON.stringify(arr))
      },
      //弹窗确认
      save(){
        if(this.canRefund) return;
        this.canRefund = true;
        let send = {
          ticketOrderDetails:JSON.parse(JSON.stringify(this.chooseArrSend)),
          cause:'',
          refundType:1,
          serviceCharge:0,
        };
        this.$api.disRefundApply(send).then(res => {
          this.$message.success('操作成功');
          this.dialogVisible = false;
          this.chooseArr = [];
          this.choosePri = 0;
          this.searchInfo = {
            searchKeyName:'',
            searchKeyNum:'',
          };
          setTimeout(() => {
            this.canRefund = false;
          },2000);
          this.getDetail();
        }).catch(() => {
          this.canRefund = false;
        })
      },
      showPop(){
        if(!this.chooseArr.length) return this.$message.error('请选择需要退款的商品');
        this.dialogVisible = true
      },
      canCheckRow(row,index){
        // refundStatus
        // PROCESSING(1L, "退款中"),
        // REFUND_SUCCESS(2L, "退款成功"),
        // REFUND_FAIL(3L, "退款失败");
        // orderDetailStatus 6 未使用
        if((!row.refundStatus || row.refundStatus == 3 ) && row.orderDetailStatus == 6){
          return true;//不禁用
        }else{
          return false;//禁用
        }

      },
      getZJlX(type){
        let name = '';
        this.cardArr.map(item => {
          if(item.value == type){
            name = item.name
          }
        });
        return name
      },
      //获取证件类型
      queryWinCredentials(){
        this.$api.queryWinCredentials().then(res => {
          this.cardArr = res.data;
        })
      },
      getDetail(){
        this.$api.queryOrderGroup({orderId :this.$route.query.orderId}).then(res => {
          this.info = res.data;
          this.details = [];
          this.refundArr = [];
          this.info.orderDetails.map(item => {
            let obj = item;
            obj.users = [];
            this.info.details.map(itm => {
              if(item.orderGroupDetailId == itm.orderGroupDetailId){
                obj.users.push(itm);
                if(itm.refundTime){
                  obj.refundTime = itm.refundTime
                }
              }
            });
            this.details.push(obj)
          });
          this.details.map(item => {
            if((!item.refundStatus || item.refundStatus == 3 ) && item.orderDetailStatus == 6){
              this.refundArr.push(item);
            }
          });
          this.tableData = JSON.parse(JSON.stringify(this.details));
          console.log(this.tableData)
        })
      },
    }
  }

</script>

<style scoped lang="scss">
  .or_bottom{
    .ch_btn{
      div{
        width: 215px;
        height: 72px;
        line-height: 70px;
      }
      .ac{
        margin-left: 65px;
      }
    }
    .bold{
      font-size: 34px;
      font-weight: bold;
      margin-right: 98px;
    }
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    margin-top: 28px;
  }
  .or_num{
    font-size: 20px;
    font-weight: bold;
    color: #333333;
  }
  .or_choose{
    >div{
      .ch{
        width: 20px;
        height: 20px;
        background: #FFFFFF;
        border: 1px solid #AAAAAA;
        box-sizing: border-box;
        display: inline-block;
        margin-right: 11px;
        position: relative;
        top: 2px;
        img{
          display: none;
          width: 15px;
        }
        &.ac{
          background: var(--el-color-primary);
          border-color: var(--el-color-primary);
          img{
            display: block;
          }
        }
      }
      &.all_or{
        margin: 0 35px 0 40px;
      }
      cursor: pointer;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }

  }
  :v-deep .el-dialog__body{
    padding: 0;
  }
  .td_pop_con{
    .ch_btn{
      div{
        width: 228px;
        height: 79px;
        line-height: 77px;
      }
      .ac{
        margin-left: 79px;
      }
    }
    .pop_or{
      width: 638px;
      height: 98px;
      background: #FEF6E7;
      border-radius: 14px;
      margin: 0 auto 66px auto;
      text-align: center;
      line-height: 98px;
      font-size: 34px;
      font-weight: 500;
      color: #FEC714;
    }
    .red_text{
      font-size: 34px;
      font-weight: bold;
    }
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 50px;
    text-align: left;
    padding: 0 41px;
    line-height: 1.8;
  }
  .t_r_info{
    .ml{
      margin-left: 135px;
    }
    font-size: 20px;
    font-weight: 400;
    color: #333333;
  }
  .ch_btn{
    div{
      display: inline-block;
      &.ac{
        margin-left: 42px;
      }
    }
    text-align: center;
  }
  .top_info{
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
  }
  .uc_title{
    margin-bottom: 38px;
  }
</style>
